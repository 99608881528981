<script setup lang="ts">
import type {
  VoixCheckboxFieldInterface,
  VoixLinkFieldInterface,
  VoixMediaFieldInterface,
  VoixWysiwygFieldInterface,
} from '@voix/types'

const props = defineProps({
  fields: {
    type: Object as PropType<{
      image: VoixMediaFieldInterface
      logo: VoixMediaFieldInterface
      copy: VoixWysiwygFieldInterface
      link: VoixLinkFieldInterface
    }>,
    required: true,
  },
})
defineSlice({
  name: { label: 'Brand card', group: 'blocks' },
  preview: 'SlicesBlocksBrandCard.jpg',
  fields: {
    image: { type: 'media', label: 'Image', breakpoints: { default: { width: 470, height: 300 } }, name: 'image' },
    logo: { type: 'media', label: 'Logo', breakpoints: { default: { width: 420, height: 120 } }, name: 'logo' },
    copy: { type: 'wysiwyg', label: 'copy', name: 'copy' },
    link: { type: 'link', label: 'link', enabled: false, name: 'link' },
  },
  slots: [],
})

const isVisible = ref(false)
function onVisibilityChange(entry: { intersectionRatio: number }) {
  if (entry.intersectionRatio * 10 > 1)
    isVisible.value = true
  else
    isVisible.value = false
}
</script>

<template>
  <div v-voix-visibility="{ callback: onVisibilityChange, threshold: [0, 0.25] }">
    <div
      class="brand-card h-full flex flex-col duration-250 ease-out" :class="{
        'opacity-0': !isVisible,
        'opacity-100': isVisible,
      }"
    >
      <VoixMedia
        :field="props.fields.image" class="cover-image w-full transition-all duration-1000 ease-out" :class="{
          'opacity-0': !isVisible,
          'opacity-100': isVisible,
        }"
      />
      <div class="body p-4 flex-1 flex flex-col justify-between">
        <div>
          <div class="flex justify-center px-8 pb-6">
            <VoixMedia v-if="props.fields.logo.value" :field="props.fields.logo" />
          </div>
          <VoixWysiwyg v-if="props.fields.copy.value" :field="props.fields.copy" class="text-sm" />
        </div>
        <div class="text-center pt-4">
          <VoixLink
            :field="props.fields.link"
            class="inline-block uppercase mx-auto bg-nonPhotoBlue py-3 px-4 text-white text-sm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.brand-card {
  @apply bg-white shadow-xl;

  .head {
    @apply text-center text-white py-2;

    p {
      @apply uppercase font-light md:text-lg;
    }
  }

  @screen max-sm {
    @apply shadow-none;
  }
}
</style>
